<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <section
    ref="algoliaContainer"
    role="search"
    :class="[
      'ais-search-container',
      {
        'container-dropdown': renderMode === 'dropdown',
        'search-open': isOpen,
        hide: navOpen,
      },
    ]"
    :aria-label="isOpen ? 'Escape to close search' : 'Enter to open search'"
    @click="handleClickIn"
    @keydown="handleKeyPress"
  >
    <SearchInput
      v-if="state.hasntOpened"
      name="header-search-placeholder"
      class="search-opener placeholder"
      :onKeydown="enterOnLabel"
      :onFocus="unfocusSearch"
      :label-attrs="{
        'aria-haspopup': true,
        tabindex: 0,
      }"
      :input-attrs="{
        tabindex: -1,
        'aria-hidden': !isOpen,
        'aria-label': 'Type for instantsearch, enter to search landing page, has dropdown results',
      }"
    />
    <AisInstantSearch
      v-else
      :search-client="searchClient"
      :index-name="aisIndexName"
      :middlewares="state.middlewares"
      :insights="true"
      class="nav-search-container"
      @view="
        () => {
          sendEvent('view', items, 'View Products');
        }
      "
    >
      <SearchInput
        ref="algoliaSearchbar"
        name="header-search"
        class="search-opener"
        :onKeydown="enterOnLabel"
        :onFocus="unfocusSearch"
        :label-attrs="{
          'aria-haspopup': true,
          tabindex: 0,
        }"
        :input-attrs="{
          tabindex: isOpen ? 0 : -1,
          'aria-hidden': !isOpen,
          'aria-label': 'Type for instantsearch, enter to search landing page, has dropdown results',
        }"
        emit-enter="enter-on-search"
        emit-input-change="input-on-search"
        emit-search-click="search-button-click"
        @enter-on-search="(e: any) => enterOnSearch(e, true)"
        @input-on-search="inputOnSearch"
        @search-button-click="(e: any) => enterOnSearch(e, true)"
      />
      <CustomButton
        v-if="isOpen"
        class="close-search-btn"
        style-name="tertiary"
        style-color="black"
        :aria-attrs="{ ariaLabel: 'Close Search' }"
        :icon-only="true"
        @click-button="closeSelf"
      >
        <template #icon>
          <CloseLine class="close-search-svg" title="close search" />
        </template>
      </CustomButton>

      <section v-if="isOpen || renderMode === 'page'" :class="[{ 'search-dropdown': renderMode === 'dropdown' }]">
        <div class="results-container">
          <AisIndex :index-name="`${aisIndexName}_query_suggestions`">
            <AisConfigure :hitsPerPage="state.aisConfig.hitsPerPage" :query="state.query" />
            <AisAutocomplete>
              <!-- eslint-disable vue/no-unused-vars -->
              <template #default="{ currentRefinement, indices, refine, ...args }">
                <!-- eslint-enable vue/no-unused-vars -->
                <div class="tile-title">{{ useTranslate('Search.popularSearches') }}</div>
                <template
                  v-for="(suggestionIndice, siKey) in indicesToSuggestions(indices)"
                  :key="siKey + '-suggestionIndices-' + suggestionIndice.indexName"
                >
                  <ul role="listbox">
                    <li
                      v-for="suggestion in suggestionIndice.data"
                      :key="siKey + '-suggestion-' + suggestion.name"
                      role="option"
                      class="autosuggest__results-item"
                    >
                      <!-- @selected="onSelect" -->
                      <NuxtLink class="query-link" :to="pathFromHit(suggestion)" @click="closeSelf">
                        <AisHighlight attribute="query" :hit="suggestion" />
                      </NuxtLink>
                    </li>
                  </ul>
                </template>
              </template>
            </AisAutocomplete>

            <div class="ais-Autocomplete-clone">
              <div class="tile-title">{{ useTranslate('Search.popularSearches') }}</div>
              <ul class="ais-Highlight-container" role="presentation">
                <li v-for="index in 4" :key="index" class="ais-Highlight ais-clone-txt">&nbsp;</li>
              </ul>
            </div>
          </AisIndex>

          <AisIndex ref="prodIndex" :index-name="aisIndexName" :data-insights-index="aisIndexName">
            <AisConfigure
              :hitsPerPage="state.aisConfig.hitsPerPage"
              :query="state.query"
              :click-analytics.camel="true"
            />
            <AisHits>
              <template #default="{ items, sendEvent }">
                <div class="tile-title">{{ useTranslate('Search.topProducts') }}</div>
                <ul class="ais-Hits-list">
                  <!-- <li v-if="items.length < 1" style="height: 500px;">
                    </li> -->
                  <li
                    v-for="(item, i) in items"
                    :key="item.objectID"
                    class="product-li-container"
                    :data-insights-object-id="item.objectID"
                    :data-insights-position="item.__position"
                    :data-insights-query-id="item.__queryID"
                    @click="
                      () => {
                        sendEvent('click', item, 'Click Product');
                        trackClick(items.length, item, i);
                      }
                    "
                  >
                    <ProductCard
                      :product="useBcAlgoliaData(item, locale)"
                      :show_add_to_cart="addToCartDisplay"
                      :render-mode="renderMode + '-search'"
                      :queryID="item.__queryID"
                      :index="i"
                      emit-link-click="closer"
                      emit-added="added"
                      @closer="
                        (e: any) => {
                          closer(e, useBcAlgoliaData(item, locale), sendEvent);
                        }
                      "
                      @added="
                        () => {
                          sendEvent('conversion', useBcAlgoliaData(item, locale), 'Add to Cart');
                          closeSelf();
                        }
                      "
                    />
                  </li>
                </ul>
              </template>
            </AisHits>

            <div class="ais-Hits-clone">
              <div class="tile-title">{{ useTranslate('Search.topProducts') }}</div>
              <ul class="ais-Hits-list" role="presentation">
                <li v-for="index in 4" :key="index" class="ais-Hits-product">
                  <div class="ais-clone-img">&nbsp;</div>
                  <div class="product-details">
                    <div class="ais-clone-txt">&nbsp;</div>
                    <div class="ais-clone-txt">&nbsp;</div>
                    <div class="ais-clone-txt">&nbsp;</div>
                  </div>
                </li>
              </ul>
            </div>
          </AisIndex>
        </div>
        <div v-if="isOpen" class="desktop-open-nav-overlay search" />
      </section>
    </AisInstantSearch>
  </section>
</template>

<script setup lang="ts">
import {
  AisInstantSearch,
  // AisSearchBox,
  AisIndex,
  AisConfigure,
  AisAutocomplete,
  AisHighlight,
  AisHits,
} from 'vue-instantsearch/vue3/es/index';
import { getAisIndex, getVariantSkuFromBase } from '~/util/algolia';
// import { GtmEventType, mapAlgoliaSearchToGtmEvent, baseEcommerceEventStructure } from '~/util/analytics';
import { useRootStore } from '~/store';
import SearchInput from '~/components/SearchInput.vue';
import { mapAlgoliaSearchClickToGtmEvent, GtmEventType } from '~/util/analytics';
import { useProductDataStore } from '~/store/product-data';

const productDataStore = useProductDataStore();
type AlgoliaMode = 'dropdown' | 'page';
type AlgoliaClick = 'click-button';
type AlgoliaClose = 'child-closer';

const emit = defineEmits(['click-button', 'child-closer']);

const searchClient = useAlgoliaRef();
const localePath = useLocalePath();
const { locale } = useI18n();

// Specific Vue Comp type
const algoliaSearchbar = ref<InstanceType<typeof SearchInput> | null>(null);
// Generic HTML Element type
const algoliaContainer = ref<HTMLElement | null>(null);

const props = defineProps({
  emitCloser: {
    type: String as () => AlgoliaClose,
    default: '',
  },
  renderMode: {
    type: String as () => AlgoliaMode,
    default: 'page',
  },
  searchDelay: {
    type: Number,
    default: 500,
  },
  emitClick: {
    type: String as () => AlgoliaClick,
    default: null,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  navOpen: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  aisConfig: {} as any,
  query: '' as string,
  timerId: null as any,
  disjunctiveFacetsRefinements: undefined,
  algoliaProductChildren: 0,
  hasntOpened: true,
  barSwapped: false,
});

const algoliaSettings = computed(() => useRootStore().algoliaSettings);
const aisIndexName = computed(() => getAisIndex());
const addToCartDisplay = computed(() => algoliaSettings.value?.dropdown_atc || false);

state.aisConfig = {
  hitsPerPage: props.renderMode === 'dropdown' ? 4 : 1000,
};

onUnmounted(() => {
  if (state.timerId) clearTimeout(state.timerId);
});
onUpdated(() => {
  if (!state.barSwapped) {
    const realBar = algoliaSearchbar.value?.$el?.querySelector('input.search-input') as HTMLElement;
    realBar?.focus();
    state.barSwapped = true;
  }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function trackClick(numItems: number, productData: any, i?: number) {
  /* eslint-disable camelcase */
  const { bcId, sku, url, sell_price, /* msrp, */ __position } = productData;

  const skuToPull = getVariantSkuFromBase(productData, state) || sku;
  const sellPrice = productDataStore.getPriceBySku(skuToPull)?.calculated_price;

  let dataPayload = {} as any;
  dataPayload.PageType = 'Search Page'; // previously in heap
  dataPayload = {
    ...dataPayload,
    ...(bcId && { productId: bcId }),
    productSKU: sku || productData.product_offerings.bc_primary_product.product.sku,
    productURL: url,
    finalPrice: sellPrice || sell_price,
    // originalPrice: msrp,
    positionOfTile: __position,
    totalTiles: numItems,
  };
  const gtm = useGtm();
  gtm?.trackEvent(mapAlgoliaSearchClickToGtmEvent(GtmEventType.searchClickItem, dataPayload as any));
}
function closeSelf(searchbarFocus = false) {
  if (props.emitCloser) emit(props.emitCloser);
  if (searchbarFocus) algoliaSearchbar.value?.$el?.focus();
}
function closer(e: Event, item: any, sendEvent: any) {
  const target = e?.target ? (e?.target as HTMLElement) : document.body;
  if (target?.closest('.options-wrapper')) return;
  sendEvent('click', item, 'Click Product');
  closeSelf();
}
async function enterOnSearch(e: string, searchbarFocus: boolean) {
  await navigateTo({
    path: localePath('/search'),
    query: { q: e },
  });
  closeSelf(searchbarFocus);
}
function enterOnLabel(e: any) {
  if (e?.target?.classList?.contains('search-input')) return;
  if (e?.code === 'Enter' || e?.key === 'Enter') {
    handleClickIn(e);
  }
}
function unfocusSearch() {
  const realBar = algoliaSearchbar.value?.$el?.querySelector('input.search-input') as HTMLElement;
  realBar?.blur();
}
function handleClickIn(e: Event) {
  const target = e.target ? (e.target as HTMLElement) : document.body;
  if ((target.classList.contains('search-input') || target.classList.contains('search-opener')) && props.emitClick) {
    if (state.hasntOpened) {
      state.hasntOpened = false;
    }
    const realBar = algoliaSearchbar.value?.$el?.querySelector('input.search-input') as HTMLElement;
    realBar?.focus();
    emit(props.emitClick);
  }
}
function handleKeyPress(e: KeyboardEvent) {
  if (e.key === 'Tab' && props.isOpen) handleTabPress(e);
  if (e.key === 'Escape' && props.emitCloser) {
    algoliaSearchbar.value?.$el?.focus();
    emit(props.emitCloser);
  }
}
function handleTabPress(e: KeyboardEvent) {
  // const algoliaContainer = this.$refs.algoliaContainer as HTMLElement;
  const focusables = algoliaContainer.value?.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="search"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
  );
  if (!focusables?.length) return;
  const iterable = (reverser = false) => {
    const starter = reverser ? (focusables.length - 1) * -1 : 0;
    const end = reverser ? 1 : focusables.length;
    let value;
    for (let j = starter; j < end; j++) {
      const i = j * (reverser ? -1 : 1);
      const style = window.getComputedStyle(focusables[i]);
      const isFocusable =
        style.getPropertyValue('display') !== 'none' && style.getPropertyValue('visibility') !== 'hidden';
      if (isFocusable) {
        value = focusables[i];
        break;
      }
    }
    return value;
  };
  const first = iterable() as HTMLElement;
  const last = iterable(true) as HTMLElement;
  if (document.activeElement === (e.shiftKey ? first : last)) {
    (e.shiftKey ? last : first).focus();
    e.preventDefault();
  }
}
function inputOnSearch(e: string) {
  if (state.timerId) clearTimeout(state.timerId);
  state.timerId = setTimeout(() => {
    state.query = e;
  }, props.searchDelay);
}
function pathFromHit(queryHit: Record<string, unknown>): string {
  // TODO: figure out why localePath freaks out with the params
  return `${localePath('/search')}?q=${queryHit?.query}`;
}
function indicesToSuggestions(indices: any) {
  return indices
    .filter(({ indexName }: any) => indexName === `${aisIndexName.value}_query_suggestions`)
    .map(({ hits, indexName }: any) => ({
      indexName,
      data: hits.map((hit: any) => {
        const [category] = (hit.instant_search && hit.instant_search.facets.exact_matches.categories) || [];
        return {
          ...hit,
          category,
          name: hit.query,
          label: hit.label,
          type: hit.type,
          data: hits,
        };
      }),
    }));
}
</script>

<style lang="scss" scoped>
:deep(.ais-search-container) {
  width: 100%;
  &.container-dropdown {
    width: initial;
    @include local-mixins.tablet_and_mobile {
      width: 100%;
    }
    @include local-mixins.desktop {
      &.search-open {
        width: 100%;
        .search-input-label {
          .search-input {
            width: 100%;
          }
        }
      }
    }
    @include local-mixins.xxl {
      .search-input-label {
        cursor: pointer;
        fill: $color-neutral-cool-900;
        &:hover {
          fill: $color-primary-600;
        }
      }
      &:not(.search-open) {
        .search-input-label {
          .search-svg-container {
            margin-right: 0;
          }
          .search-input {
            margin-left: 0;
            padding-left: 0;
          }
        }
        .nav-search-container {
          margin-right: 0;
        }
      }
    }
  }
}
.nav-search-container {
  display: flex;
  align-items: center;
}
.search-input-label {
  flex: 1;
  @include local-mixins.desktop {
    margin-right: 0.5rem;
    height: 38px;
    border-radius: 20px;
  }
}
.close-search-btn {
  display: none;
  @include local-mixins.desktop {
    display: flex;
    background-color: transparent;
    z-index: 1;
    margin-left: -40px;
  }
  &:hover {
    .close-search-svg {
      fill: $color-primary-600;
    }
  }
  &:focus {
    .close-search-svg {
      fill: $color-primary-500;
    }
  }
  .close-search-svg {
    margin-right: 0;
  }
}
.hide {
  @include local-mixins.tablet_and_mobile {
    display: none;
  }
}
.search-dropdown {
  border-top: 1px solid $color-neutral-cool-200;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  @include local-mixins.desktop {
    top: 115px;
    width: 100vw;
    background-color: $color-neutral-white;
    padding: #{local-functions.rem-calc(0 0 15)};
    box-shadow: 0 4px 8px rgb(0 0 0 / 24%);
    // &::after {
    //   content: '';
    //   position: absolute;
    //   margin-top: 1rem;
    //   left: 0;
    //   width: 100%;
    //   height: 100vh;
    //   background-color: rgba(0, 0, 0, 0.4);
    // }
  }
}
.tile-title {
  margin: #{local-functions.rem-calc(16 0 0)};
  color: $color-neutral-cool-900;
  font-weight: 500;
  font-size: 1.25rem;
  padding-bottom: 1rem;
  @include local-mixins.desktop {
    font-size: 1.5rem;
    margin: #{local-functions.rem-calc(48 0 0)};
    padding-bottom: 1.5rem;
  }
}
.results-container {
  background-color: $color-neutral-white;
  height: 100vh;
  overflow: auto;
  display: grid;
  padding: #{local-functions.rem-calc(0 16 250)};
  @include local-mixins.tablet {
    padding: #{local-functions.rem-calc(0 16 250)};
  }
  @include local-mixins.desktop {
    grid-template-columns: 1fr 3.31fr;
    column-gap: 2rem;
    height: 100%;
    padding: 0.5rem calc((100vw - 90rem + 30rem) / 4) 3.5rem;
  }
}
.ais-Autocomplete + .ais-Autocomplete-clone {
  display: none;
}
.ais-Hits + .ais-Hits-clone {
  display: none;
}
:deep(.ais-Autocomplete) {
  .autosuggest__results ul {
    @include local-mixins.tablet_and_mobile {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  .autosuggest__results-item {
    font-size: 1.25rem;
    font-weight: 500;
    color: $color-neutral-cool-900;
    @include local-mixins.tablet_and_mobile {
      font-size: 1rem;
    }
    &:not(:last-child) {
      margin-bottom: 0.875rem;
      @include local-mixins.tablet_and_mobile {
        margin-bottom: 0.5rem;
      }
    }
  }
}
.ais-Hits-clone {
  .ais-Hits-product {
    background-color: $color-neutral-cool-50;
  }
}
.ais-clone-img {
  height: 167px;
  width: 167px;
  background-color: $color-neutral-cool-200;
  border-radius: $border-radius-4;
}
.ais-Hits-list {
  display: grid;
  row-gap: #{local-functions.rem-calc(15)};
  @include local-mixins.tablet_and_mobile {
    grid-template-columns: 1fr 1fr;
    column-gap: #{local-functions.rem-calc(15)};
  }
  @include local-mixins.desktop {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1.5rem;
  }
  :deep(.product-card) {
    .product-title {
      font-size: 1rem;
    }
  }
}
.query-link {
  color: inherit;
  :deep(.ais-Highlight) {
    .ais-Highlight-highlighted {
      background-color: transparent;
    }
  }
}
.ais-Hits-product {
  border: 1px solid $color-neutral-cool-50;
  border-radius: #{local-functions.rem-calc($border-radius-4)};
  box-shadow: 0 0 7px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0 #{local-functions.rem-calc(4)};
  img {
    width: 58.8%;
    height: auto;
    border-radius: $border-radius-4;
  }
}
.product-details {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: $color-neutral-cool-900;
  div {
    margin-bottom: 0.75rem;
    text-align: center;
  }
}
.ais-product-title {
  line-height: 150%;
  @include local-mixins.desktop {
    font-size: 1.25rem;
  }
}
</style>

<template>
  <header
    ref="headerElement"
    class="header-container"
    :class="{ 'no-search': isMobileOrTablet && !isHomepage ? !navStore.searchIsOpen : false }"
    @keydown="handleKeyPress"
  >
    <div ref="headerContent" class="header-content flex-inline">
      <div :class="['navs-full', 'navs-full--top']">
        <CustomButton
          class="menu-dropdown-controls"
          style-name="tertiary"
          :aria-label="navStore.navOpen ? 'Close Nav Menu' : 'Open Nav Menu'"
          :aria-expanded="navStore.navOpen"
          @click-button="navStore.handleNavDropdown"
        >
          <MenuLine v-if="navStore.navMode === 'navigation'" class="menu-dropdown-controls-svg" />
          <CloseLine v-else class="menu-dropdown-controls-svg" />
        </CustomButton>

        <NuxtLink :to="localePath('/')" class="fire-logo" :aria-label="`${brand?.brand_name} Home`" />

        <!-- For Mobile only, desktop is in Main Nav, TODO - maybe update -->
        <Algolia
          v-if="isMobileOrTablet && !isHomepage ? navStore.searchIsOpen : isMobileOrTablet"
          :class="[{ 'big-flex': navStore.isSearch }, 'tab-mob-only']"
          :is-open="navStore.navMode === 'search'"
          render-mode="dropdown"
          :nav-open="navStore.navOpen"
          emit-click="click-button"
          emit-closer="child-closer"
          @click-button="navStore.openSearch"
          @child-closer="navStore.algoliaClose"
        />

        <UserNavHeader class="usernav-header" @search-clicked="navStore.toggleSearch()" />
      </div>

      <hr class="header-divider" />

      <div :class="['navs-full', 'navs-full--bottom']">
        <MainNav>
          <div v-if="navStore.navOpen" class="mobile-closers tab-mob-only-flex between">
            <NuxtLink
              v-if="navStore.subnavOpenIndex === null"
              aria-current="page"
              aria-label="Solo Stove Home"
              class="fire-logo tab-mob-only nav-swap"
              :to="localePath('/')"
            />
            <CustomButton
              v-else
              class="back-button tab-mob-only-flex"
              style-name="tertiary"
              style-color="black"
              @click-button="
                () => {
                  navStore.setSubnavOpenIndex(null);
                }
              "
            >
              <ArrowLeftSLine />
              {{ navStore.selectedMobileNavTitle }}
            </CustomButton>
            <CustomButton
              style-name="tertiary"
              style-color="black"
              :icon-only="true"
              @click-button="navStore.handleNavDropdown"
            >
              <CloseLine />
            </CustomButton>
          </div>
        </MainNav>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useNavStore } from '~/store/nav';
import { useRootStore } from '~/store';
import { useDeviceStore } from '~/store/device';
import { useLocaleStore } from '~/store/locale';

const localePath = useLocalePath();
const route = useRoute();
const deviceStore = useDeviceStore();
const navStore = useNavStore();

const isMobileOrTablet = computed(() => deviceStore.isMobileOrTablet);
const langCode = computed(() => useLocaleStore().langCode);
const brand = computed(() => useRootStore().brandSettings);
const path = computed(() => route.path?.toLowerCase());
const isHomepage = computed(() => path.value === '/' + langCode.value || path.value === '/' + langCode.value + '/');
const dropdownOpen = computed(() => navStore.isSearch || navStore.navOpen);

onMounted(() => {
  document.body.addEventListener('click', navStore.closeNavs);
});

onUnmounted(() => {
  document.body.removeEventListener('click', navStore.closeNavs);
});

function handleKeyPress(e: KeyboardEvent) {
  if (e.key === 'Tab' && dropdownOpen.value) handleTabPress(e);
  if (e.key === 'Escape' && dropdownOpen.value) navStore.navResetter();
}

function handleTabPress(e: KeyboardEvent) {
  const headerElement = e.target as HTMLElement;
  const focusables = headerElement?.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="search"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
  );
  const iterable = (reverser = false) => {
    const starter = reverser ? (focusables.length - 1) * -1 : 0;
    const end = reverser ? 1 : focusables.length;
    let value;
    for (let j = starter; j < end; j++) {
      const i = j * (reverser ? -1 : 1);
      const style = window.getComputedStyle(focusables[i]);
      const isFocusable =
        style.getPropertyValue('display') !== 'none' && style.getPropertyValue('visibility') !== 'hidden';
      if (isFocusable) {
        value = focusables[i];
        break;
      }
    }
    return value;
  };
  const first = iterable() as HTMLElement;
  const last = iterable(true) as HTMLElement;

  if (document.activeElement === (e.shiftKey ? first : last)) {
    (e.shiftKey ? last : first).focus();
    e.preventDefault();
  }
}
</script>

<style lang="scss">
body.search-open {
  .header-divider {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.back-button {
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  padding-left: 0;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 500;
  text-transform: none;
  white-space: nowrap;
  svg {
    margin-right: 0;
    margin-left: -8px;
  }
}

.header-container {
  position: sticky;
  display: flex;
  width: 100%;
  height: 112px;
  z-index: map.get(local-vars.$zindex, 'fixed');
  @include local-mixins.tablet_and_mobile {
    top: 0;
    .header-content {
      position: unset;
    }
    &.no-search {
      height: 65px;
    }
  }
  @include local-mixins.desktop {
    height: 116px;
  }

  .menu-dropdown-controls {
    padding-right: 0.75rem;
    width: auto;

    @include local-mixins.desktop {
      display: none;
    }

    & :deep(.menu-dropdown-controls-svg) {
      svg {
        fill: $color-neutral-cool-900;
        margin-right: 0;
      }

      &:hover,
      &:focus {
        fill: $color-primary-600;
      }
    }
  }

  .mobile-closers {
    margin: 0 1.25rem;
    padding: 8px 0;
    .btn {
      width: auto;
    }
  }

  :deep(.menu-item.btn) {
    @include local-mixins.desktop {
      .dropdown-svg {
        display: none;
      }
    }
  }
}

.big-flex {
  @include local-mixins.desktop {
    flex: 1;
  }
}

.header-content {
  background-color: $color-neutral-white;
  border-bottom: 1px solid $color-neutral-cool-200;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  z-index: map.get(local-vars.$zindex, 'page');
  flex-wrap: wrap;

  @include local-mixins.tablet_and_mobile {
    padding: local-vars.$mob-page-margin;
  }
  @include local-mixins.desktop {
    justify-content: space-between;
    margin: 0;
    padding: 0 local-vars.$desktop-content-padding;
  }
}

.navs-full :deep() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: #{local-functions.rem-calc(3440)};

  @include local-mixins.xxl {
    padding: 0 rem;
  }

  .usernav-header {
    margin-left: auto;
    padding: 0;
  }

  .ais-search-container {
    @include local-mixins.tablet_and_mobile {
      order: 3;
      margin-top: 0.5rem;
      width: 100%;
    }
    &.container-dropdown {
      @include local-mixins.desktop {
        margin-left: auto;
        margin-right: 12px;
        width: 100%;
        max-width: #{local-functions.rem-calc(220)};
      }
      @include local-mixins.xxl {
        width: 38%;
      }
    }
  }
}

.navs-full--top {
  @include local-mixins.desktop {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
}

.header-divider {
  width: 100vw;
  left: 0;
  position: absolute;
  margin-bottom: 0;
  margin-top: -10px;
  background-color: $color-neutral-cool-200;
  border: none;
  height: 1px;
  z-index: 0;
  @include local-mixins.tablet_and_mobile {
    display: none;
  }
}

.fire-logo {
  @include local-mixins.backgroundImage(local-functions.svg-img-source('header-logo.svg'), 33px, 151px);

  margin-bottom: 7px;

  @include local-mixins.tablet_and_mobile {
    width: 120px;
  }
  @include local-mixins.desktop {
    margin: 0 #{local-functions.rem-calc(30)} 0 0;
  }
}

.ais-search-container :deep() {
  .search-input {
    width: auto;
    flex-grow: 2;
  }
  .search-input-label {
    justify-content: center;
    padding: #{local-functions.rem-calc(7)} 0.625rem;
    margin: 0;
  }

  .search-input-filled {
    width: 100%;
    padding-right: 12.5px;
  }

  .search-svg-container {
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
    margin-right: 0.625rem;
    :deep(svg) {
      width: #{local-functions.rem-calc(22)};
      height: auto;
    }
  }
}
</style>
